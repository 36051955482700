export const theme = {
	palette: {
		background: {
			default: "#ffffff",
			light: "#EEF8FF"
		},
		primary: {
			light: "#FFE6A6",
			main: "#FFB800",
			dark: "#CC9300"
		},
		secondary: {
			light: "#0B6DA8",
			main: "#015181",
			dark: "#00304C"
		},
		text: {
			primary: "#303030",
			secondary: "#666666"
		}
	},
	typography: {
		fontFamily: "'Roboto'",
		fontSize: 18,
		fontWeight: 400,
		lineHeight: "1.5"
	},
	shape: {
		borderRadius: 4
	},
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiButtonGroup: {
			disableElevation: true
		}
	}
};
