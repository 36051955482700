import React, { Suspense } from "react";
import loadable from "@loadable/component";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { StylesProvider } from "@material-ui/core/styles";
import Ui from "@micado-digital/react-ui/components/Ui";
import { theme as customTheme } from "./themes/project";
import "./css/googlefonts.css";

const Facts = loadable(() => import("./lib/components/Facts"));
const Ratings = loadable(() => import("./lib/components/Ratings"));
const Timeline = loadable(() => import("./lib/components/Timeline"));

const theme = createTheme(customTheme);

const $facts = document.querySelectorAll("#facts");
const $timeline = document.querySelectorAll("#timeline");
const $ratings = document.querySelectorAll("#react-rating-detail");

if ($ratings) {
	$ratings?.forEach(element => {
		const headlineCategories = element?.getAttribute("data-headline-categories");
		const headlineSources = element?.getAttribute("data-headline-sources");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<Ui>
							<Ratings
								headlineCategories={headlineCategories}
								headlineSources={headlineSources}
							/>
						</Ui>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

if ($timeline) {
	$timeline?.forEach(element => {
		const lang = element?.getAttribute("data-lang");
		const anchor = element?.getAttribute("data-anchor");
		const id = element?.getAttribute("data-id");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<Routes>
									<Route
										path="/*"
										element={<Timeline anchor={anchor} id={id} lang={lang} />}
									/>
								</Routes>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}

if ($facts) {
	$facts?.forEach((element, index) => {
		const buttonFallback = element?.getAttribute("data-button-fallback");
		const id = element?.getAttribute("data-id");
		const lang = element?.getAttribute("data-lang");

		ReactDOM.render(
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<Suspense fallback={<></>}>
						<BrowserRouter>
							<Ui>
								<Routes>
									<Route
										path="/*"
										element={<Facts buttonFallback={buttonFallback} id={id} lang={lang} />}
									/>
								</Routes>
							</Ui>
						</BrowserRouter>
					</Suspense>
				</StylesProvider>
			</ThemeProvider>,
			element
		);
	});
}
